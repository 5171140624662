.mainHome {
  width: 100%;
  overflow: hidden;
  padding: 8rem 0;
  background-image: url(../images/shadergradient.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  /* background: linear-gradient(to bottom left, #ffeb3b, white 60%);
  background-size: 200% 200%;
  animation: windBlowing 10s infinite cubic-bezier(0.4, 0, 0.2, 1); */
}
/* 
@keyframes windBlowing {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 100%;
  }
} */

.homeContainer {
  position: relative;
  /* border: 1px solid burlywood; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  width: 90%;
  margin: auto;
}

.smoke {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ffeb3b; /* Yellow color */
  border-radius: 50%;
  opacity: 0;
  animation: moveSmoke 4s infinite linear;
}

@keyframes moveSmoke {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(calc(var(--x) * 1px), calc(var(--y) * 1px)) scale(5);
    opacity: 0;
  }
}

.heroContent {
  /* border: 1px solid greenyellow; */
  width: 50%;
}

.heroContent h1 {
  color: #000;
  font-family: var(--font_outfit);
  font-size: 3.5rem;
  font-weight: 700;
  letter-spacing: 0.025rem;
}

.heroContent p {
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--font_outfit);
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 156%; /* 1.56rem */
  margin-top: 1rem;
}

.swapNowButton {
  margin-top: 1rem;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: #e8b124;
  color: #000;
  font-family: var(--font_outfit);
  font-size: 1.7rem;
  font-weight: 500;
  border: none;
  transition: all 0.3s ease;
}

.swapNowButton:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  letter-spacing: 0.1rem;
}

.heroImage {
  margin: auto;
  position: relative;
}

.heroGlobeImage {
  position: absolute;
  width: 20%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@media (max-width: 1280px) {
  .heroContent {
    /* border: 1px solid greenyellow; */
    width: 65%;
  }

  .heroContent h1 {
    width: 90%;
  }
  .heroContent p {
    font-size: 2rem;
  }
}

@media (max-width: 1024px) {
  .heroContent h1 {
    font-size: 3rem;
  }

  .heroContent p {
    font-size: 1.5rem;
  }
}

@media (max-width: 900px) {
  .mainHome {
    padding-bottom: 7rem;
  }

  .heroContent {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 80%;
    width: 75%;
    border-radius: 1.25rem;
    background: #fff;
    box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.07),
      -2px -2px 25px 0px rgba(0, 0, 0, 0.07);
    padding: 1.75rem;
    z-index: 10;
  }

  .heroContent h1 {
    font-size: 3rem;
    text-align: center;
  }

  .heroContent p {
    text-align: center;
    font-size: 1.6rem;
    margin-top: 1.5rem;
  }

  .swapNowButton {
    margin-top: 1.5rem;
  }
}

@media (max-width: 768px) {
  .mainHome {
    padding-bottom: 10rem;
  }

  .heroContent {
    top: 80%;
    width: 80%;
  }
}

@media (max-width: 562px) {
  .mainHome {
    padding-bottom: 14rem;
  }

  .heroContent {
    top: 85%;
    width: 100%;
    padding: 1.2rem;
  }

  .heroContent h1 {
    font-size: 2.8rem;
  }

  .heroContent p {
    font-size: 1.5rem;
  }

  .swapNowButton {
    margin-top: 1.5rem;
  }
}

@media (max-width: 428px) {
  .mainHome {
    padding-bottom: 13rem;
  }

  .heroContent {
    top: 85%;
    padding: 1.2rem;
  }

  .heroContent h1 {
    font-size: 2.3rem;
  }

  .heroContent p {
    font-size: 1.2rem;
  }

  .swapNowButton {
    margin-top: 1.5rem;
    height: 3.3rem;
    font-size: 1.4rem;
  }
}

@media (max-width: 390px) {
  .mainHome {
    padding-bottom: 15rem;
  }

  .heroContent {
    top: 95%;
  }

  .heroContent h1 {
    font-size: 1.7rem;
  }

  .heroContent p {
    font-size: 1.1rem;
  }
}
