.coinModal_box {
  width: 20vw !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  max-height: 60vh;
  gap: 20px;
  padding: 20px 0px;
}

.coinModal_box > div:nth-child(1) {
  margin: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.coinModal_box > div > h3 {
  color: black;
  font-size: 2rem;
}
.coinModal_box > div > h3:nth-child(2) {
  cursor: pointer;
}

.coinModal_box * {
  transition: none !important;
  font-family: var(--font_outfit);
  transform: none !important;
}

.coinModal_box > input {
  width: calc(100% - 20px);
  border: 1px solid gray;
  outline: none;
  padding: 7px 10px;
}

.coinModal_token_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.coinModal_token_list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.coinModal_token_list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.coinModal_token_list > div {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: none !important;
  padding: 10px 10px;
  transition: all;
}

.coinModal_token_list > div:hover {
  background-color: var(--background);
  cursor: pointer;
}

.coinModal_token_list > div > img {
  height: 200%;
}

.coinModal_token_list > div > p {
  color: black;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .coinModal_box {
    width: 70vw !important;
  }
}
