.heading_description {
  margin-top: 1rem;
  background: rgba(222, 218, 218, 0.31);
  padding: 3rem 0;
}

.heading_description > div {
  width: 60%;
  margin: auto;
}

.heading_description > div > h1 {
  color: #e8b124;
  font-family: var(--font_outfit);
  font-size: 4rem;
  font-weight: 700;
}

.heading_description > div > p {
  margin-top: 1rem;
  color: #000;
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.swap_main {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white_background);
  padding: 5rem 0;
  position: relative;
}

.swap_content_container {
  width: 60%;
  padding: 3.25rem 5.875rem;
  border-radius: 1.25rem;
  background: var(--background);
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.swap_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.swap_heading > p {
  color: var(--white);
  font-family: var(--font_outfit);
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.swap_heading > div > img {
  width: 3.5rem;
  cursor: pointer;
  transition: all 0.5s;
}

.swap_heading > div > img:hover {
  transform: scale(1.2) rotate(180deg);
}

.swap_deposit_heading {
  color: var(--white);
  font-family: var(--font_outfit);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2rem;
}

/* .swap_sell_buy_container {
  border: 1px solid red;
} */

.swap_sell_buy_item {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.swap_item {
  width: 300px;
}

.swap_item > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.swap_item_balance {
  color: var(--white);
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
}

.swap_balance {
  display: flex;
  padding: 1.75rem 2rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem;
  background: var(--white_background);
}

.swap_amount > input {
  border: none;
  width: 16rem;
  outline: none;
  color: var(--black);
  font-family: var(--font_outfit);
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.swap_amount > input[type="number"]::-webkit-inner-spin-button,
.swap_amount > input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.swap_balance .swap_approx {
  color: var(--light_gray);
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.swap_coin {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.swap_coin > div {
  width: 2.8rem;
  height: 2.8rem;
}

.swap_coin > div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swap_coin p {
  color: var(--redish_gray);
  font-family: var(--font_outfit);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.swap_left_right {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 4.375rem;
  height: 4.375rem;
  padding: 0.7rem;
  background: var(--white_background);
  cursor: pointer;
  transition: all 0.5s;
}
.swap_left_right img {
  border-radius: 50%;
  width: 200%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s;
}

.swap_left_right:hover img {
  transform: rotate(180deg);
}

.swap_coin_details {
  width: 90%;
  margin: auto;
  margin-top: 3.2rem;
}

.swap_coin_details > p {
  color: var(--white);
  font-family: var(--font_outfit);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.3rem;
}

.swap_coin_details > div > p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: var(--white);
  font-family: var(--font_outfit);
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.8rem;
}

.swap_button,
.swap_button div,
.swap_button button {
  display: flex;
  height: 4.5rem;
  width: 100%;
  /* padding: 0.9375rem 1.875rem; */
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.75rem;
  background: var(--black) !important;
  color: var(--white);
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin-top: 3.25rem;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid transparent;
}

.swap_button div,
.swap_button button {
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
}

.swap_button:hover {
  background: transparent;
  color: black;
  border: 2px solid black;
}

/* responsive  */

@media (max-width: 1024px) {
  .swap_sell_buy_item {
    flex-direction: column;
    gap: 2rem;
  }

  .swap_left_right {
    transform: rotate(90deg);
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .heading_description > div {
    width: 70%;
  }

  .heading_description > div > h1 {
    font-size: 3.5rem;
  }

  .heading_description > div > p {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .swap_content_container {
    width: 70%;
    padding: 3.25rem 3.875rem;
  }
  .swap_sell_buy_item {
    flex-direction: column;
  }

  .swap_left_right {
    transform: rotate(90deg);
  }
}

@media (max-width: 550px) {
  .heading_description > div {
    width: 90%;
  }

  .heading_description > div > h1 {
    font-size: 3rem;
  }

  .heading_description > div > p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .swap_content_container {
    width: 90%;
    padding: 3.25rem 2rem;
    gap: 1.5rem;
  }

  .swap_heading > p {
    font-size: 2.5rem;
  }

  .swap_heading > div > img {
    width: 2.5rem;
  }

  .swap_deposi_heading {
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }

  .swap_left_right {
    transform: rotate(90deg);
    width: 3rem;
    height: 3rem;
  }

  .swap_coin_details {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .swap_balance {
    width: 25rem;
    margin: auto;
  }

  .swap_item_balance {
    padding: 0 3rem;
  }
}
