.mainSimplify {
  width: 100%;
  overflow: hidden;
  border-radius: 3rem;
  padding: 7rem 0;
  background-image: url(../images/shadergradient.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  /* background: linear-gradient(to bottom left, #ffeb3bb8, white 80%);
  background-size: 200% 200%;
  animation: windBlowing 10s infinite cubic-bezier(0.4, 0, 0.2, 1); */
}

/* @keyframes windBlowing {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
} */

.simplifyContainer {
  width: 90%;
  margin: auto;
}

/* --------------------------------------------------  */

.simplifyContent {
  /* border: 1px solid greenyellow; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.simplifyContent > p:first-child {
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--font_outfit);
  text-align: center;
  font-size: 2.125rem;
  font-weight: 500;
  line-height: 137.5%; /* 1.54688rem */
}

.simplifyContent > h1 {
  color: #000;
  font-family: var(--font_outfit);
  text-align: center;
  font-size: 3.3rem;
  font-weight: 700;
  line-height: 2.90625rem; /* 116.25% */
}

.simplifyContent > p:nth-child(3) {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-family: var(--font_outfit);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 137.5%; /* 2.0625rem */
}

.simplifyStakingPool {
  border-radius: 0.75rem;
  border: 1px solid #e8b124;
  background: #fff;

  display: flex;
  height: 48px;
  padding: 1rem 4.875rem;
  justify-content: center;
  align-items: center;

  color: #191919;
  font-family: var(--font_outfit);
  font-size: 1.7rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.simplifyStakingPool:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #e8b124;
}

/* -------------------------------------------------  */

.simplifyItems {
  /* border: 1px solid burlywood; */
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.simplifyItems > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.simplifyItems > div svg {
  width: 5rem;
}

.simplifyItems > div h3 {
  color: #000;
  text-align: center;
  font-family: var(--font_outfit);
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 2.90625rem; /* 129.167% */
}

.simplifyItems > div p {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-family: var(--font_outfit);
  font-size: 1.8rem;
  font-weight: 500;
}

.simplifyItemsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  gap: 2rem;
}

.simplifyNoeToken {
  border-radius: 0.75rem;
  border: 1px solid #e8b124;
  background: #fff;

  display: flex;
  height: 48px;
  padding: 0.9375rem 2.875rem;
  justify-content: center;
  align-items: center;

  color: #191919;
  font-family: var(--font_outfit);
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.simplifyNoeToken:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #e8b124;
}

.simplifyAddLiquidity {
  border-radius: 0.75rem;
  background: #e8b124;

  display: flex;
  height: 48px;
  padding: 0.9375rem 4.875rem;
  justify-content: center;
  align-items: center;

  color: #191919;
  font-family: var(--font_outfit);
  font-size: 1.7rem;
  font-weight: 600;
  border: none;
  transition: all 0.3s ease;
}

.simplifyAddLiquidity:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
  scale: 1.01;
}

.simlifyTokenLink {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  color: rgba(0, 0, 0, 0.5);
  font-family: var(--font_outfit);
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: lowercase;
  cursor: pointer;

  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.tokenLinkIcon {
  font-size: 2.5rem;
}

.simlifyTokenLink:hover {
  gap: 3rem;
  color: #e8b124 !important;
}

/* responsive  */

@media (max-width: 1280px) {
  .simplifyContent > p:first-child {
    font-size: 2rem;
  }

  .simplifyContent > h1 {
    font-size: 3rem;
  }

  .simplifyContent > p:nth-child(3) {
    font-size: 1.6rem;
  }

  .simplifyItems > div svg {
    width: 4.5rem;
  }

  .simplifyItems > div h3 {
    font-size: 3rem;
  }

  .simplifyItems > div p {
    font-size: 1.7rem;
  }

  .simlifyTokenLink {
    margin-top: 3rem;
    font-size: 1.7rem;
  }
}

@media (max-width: 1024px) {
  .simplifyContent > h1 {
    font-size: 2.8rem;
  }

  .simplifyContent > p:nth-child(3) {
    font-size: 1.4rem;
  }

  .simplifyItems > div svg {
    width: 4.2rem;
  }

  .simplifyItems > div h3 {
    font-size: 2.5rem;
  }

  .simplifyItems > div p {
    font-size: 1.4rem;
  }

  .simlifyTokenLink {
    margin-top: 3rem;
    font-size: 1.7rem;
  }
}

@media (max-width: 900px) {
  .simplifyItems {
    margin: auto;
    margin-top: 5rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem;
    width: 60%;
  }

  .simplifyItems > div svg {
    width: 5rem;
  }

  .simplifyItems > div h3 {
    font-size: 3.5rem;
  }

  .simplifyItems > div p {
    font-size: 1.8rem;
  }

  .simplifyNoeToken {
    padding: 0.9375rem 5.875rem;
  }

  .simplifyAddLiquidity {
    padding: 0.9375rem 6.875rem;
  }
}

@media (max-width: 768px) {
  .simplifyItems {
    width: 70%;
  }
}

@media (max-width: 562px) {
  .simplifyItems {
    width: 90%;
  }
  .simplifyItems > div svg {
    width: 5rem;
  }

  .simplifyItems > div h3 {
    font-size: 3.5rem;
  }

  .simplifyItems > div p {
    font-size: 1.8rem;
  }

  .simplifyItemsButton {
    display: grid;
    grid-template-columns: 1fr;
  }
  .simlifyTokenLink {
    font-size: 1.5rem;
  }

  .tokenLinkIcon {
    font-size: 2rem;
  }
}

@media (max-width: 428px) {
  .simplifyItems {
    width: 95%;
  }
  .simplifyContent > h1 {
    font-size: 2.3rem;
  }

  .simplifyItems > div svg {
    width: 5rem;
  }

  .simplifyItems > div h3 {
    font-size: 3rem;
  }

  .simplifyItems > div p {
    font-size: 1.6rem;
  }

  .simlifyTokenLink {
    gap: 1rem;
  }

  .simlifyTokenLink {
    font-size: 1.2rem;
  }

  .tokenLinkIcon {
    font-size: 1.5rem;
  }
}

@media (max-width: 390px) {
  .simplifyItems {
    width: 100%;
  }

  .simlifyTokenLink {
    font-size: 1.1rem;
  }

  .tokenLinkIcon {
    font-size: 1.2rem;
  }
}
