.liquidity_main {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white_background);
  padding: 5rem 0;
}

.liquidity_content_container {
  width: 60%;
  padding: 3.25rem 5.875rem;
  border-radius: 1.25rem;
  background: var(--background);
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  position: relative;
}

.liquidity_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.liquidity_heading > p {
  color: var(--white);
  font-family: var(--font_outfit);
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.liquidity_heading > div > img {
  width: 3.5rem;
  cursor: pointer;
  transition: all 0.5s;
}

.liquidity_heading > div > img:hover {
  transform: scale(1.2) rotate(180deg);
}

/* .liquidity_deposit_money {
  border: 1px solid greenyellow;
} */
/* 
.liquidity_container {
  border: 1px solid red;
} */

.liquidity_sell_buy_item {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.liquidity_item {
  width: 29rem;
}

.liquidity_item > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.liquidity_balance {
  display: flex;
  padding: 1.75rem 2rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.75rem;
  background: var(--white_background);
}

.liquidity_amount > input {
  border: none;
  width: 16rem;
  outline: none;
  color: var(--black);
  font-family: var(--font_outfit);
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.liquidity_amount > input[type="number"]::-webkit-inner-spin-button,
.liquidity_amount > input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.liquidity_balance .liquidity_approx {
  color: var(--light_gray);
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.liquidity_coin {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.liquidity_coin > div {
  width: 2.8rem;
  height: 2.8rem;
}

.liquidity_coin > div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.liquidity_coin p {
  color: var(--redish_gray);
  font-family: var(--font_outfit);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.liquidity_left_right {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 4.375rem;
  height: 4.375rem;
  padding: 0.7rem;
  background: var(--white_background);
  cursor: pointer;
}
.liquidity_left_right img {
  border-radius: 50%;
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.liquidity_three_container {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.liquidity_three_item {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 2rem;
  border-radius: 0.75rem;
  background: #fff;
}

.liquidity_three_item > p:first-child {
  color: #191919;
  text-align: center;
  font-family: var(--font_outfit);
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.liquidity_three_item > p:last-child {
  color: rgba(0, 0, 0, 0.33);
  font-family: var(--font_outfit);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.liquidity_button , .liquidity_button div, .liquidity_button button{
  display: flex;
  height: 4.5rem;
  width: 100%;
  /* padding: 0.9375rem 1.875rem; */
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.75rem;
  background: var(--black) !important;
  color: var(--white);
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin-top: 3.25rem;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid transparent;
}

.liquidity_button div, .liquidity_button button{
  width: 100% !important;
  height: 100% !important;
  margin-top: 0 !important;
}

.liquidity_button:hover {
  background: transparent;
  color: black;
  border: 2px solid black;
}

@media (max-width: 1024px) {
  .liquidity_content_container {
    width: 80%;
    padding: 3.25rem 4.5rem;
  }

  .liquidity_sell_buy_item {
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .liquidity_content_container {
    width: 80%;
    padding: 4.25rem 4rem;
  }

  .liquidity_heading > div > img {
    width: 3rem;
  }

  .liquidity_sell_buy_item {
    flex-direction: column;
    gap: 3rem;
  }

  .liquidity_item {
    width: 100%;
  }

  .liquidity_three_container {
    flex-direction: column;
    gap: 1.5rem;
  }

  .liquidity_three_item {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .liquidity_heading > p {
    font-size: 2.5rem;
  }

  .liquidity_content_container {
    width: 95%;
    padding: 3.25rem 2.5rem;
  }
}
