.mainDiscover {
  /* border: 1px solid red; */
  width: 100%;
  padding: 5rem 0;
  position: relative;
}

.discoverContainer {
  /* border: 1px solid burlywood; */
  margin: auto;
  display: flex;
  align-items: center;
  margin-left: 5%;
  gap: 3rem;
}

/* .discverContent {
  border: 1px solid greenyellow;
} */

.discverContent > h1 {
  color: #000;
  font-family: var(--font_outfit);
  font-size: 4rem;
  font-weight: 600;
}

.discverContent > p {
  margin: 2rem 0;
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--font_outfit);
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 137.5%; /* 1.54688rem */
}

.disoverNOEbutton {
  width: 60%;
  border-radius: 0.75rem;
  background: #e8b124;

  display: flex;
  height: 48px;
  padding: 0.9375rem 1.875rem;
  justify-content: center;
  align-items: center;

  color: #191919;
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  transition: all 0.3s ease;
}

.disoverNOEbutton:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
  scale: 1.01;
}

/* responsive  */

@media (max-width: 1280px) {
}

@media (max-width: 1024px) {
  .discverContent > h1 {
    font-size: 3.2rem;
  }

  .discverContent > p {
    font-size: 1.5rem;
  }

  .disoverNOEbutton {
    height: 48px;
  }
}

@media (max-width: 900px) {
  .discoverContainer {
    width: 90%;
    margin: auto;
  }
  .discoverImage {
    display: none;
  }

  .discverContent > h1 {
    text-align: center;
    font-size: 3rem;
  }

  .discverContent > p {
    text-align: center;
    font-size: 1.5rem;
  }

  .disoverNOEbutton {
    height: 48px;
    margin: auto;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 562px) {
  .discverContent > h1 {
    font-size: 2.7rem;
  }

  .discverContent > p {
    font-size: 1.2rem;
  }
}

@media (max-width: 428px) {
  .discverContent > h1 {
    font-size: 2.2rem;
  }

  .disoverNOEbutton {
    font-size: 1.3rem;
  }
}

@media (max-width: 390px) {
  .disoverNOEbutton {
    width: 100%;
  }
}
