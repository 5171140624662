.earn-buttons {
    width: 200px;
    background: linear-gradient(90.41deg, #4FA7F3 0.31%, #7CF0B2 99.66%);
    border-radius: 9px;
    border: none;
}

.tandc{
    margin-top: 5px;
}


.earn-btn {
    text-align: center;
    padding-bottom: 10px;   
}

.info-text {
    font-size: 15px;
    margin-top: 10px;
    text-align: center;
}

.highlight {
    h2 {
        font-weight: 800;
        color: #99F3FF;
    }

}

.placeholder {
    height: 30px;
    width: 30px;
    float: right;
    background: linear-gradient(67.44deg, #F0EF59 10.54%, #8FEB99 85.33%);
    border-radius: 50px;
}

.column {
    float: left;
    width: 50%;
    padding: 10px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.floating-card {
    font-size: 0.8rem;
    position: absolute;
    right: 42px;
    top: -14px;
    width: 150px;
    background: linear-gradient(90deg, #4EA5F4 0%, #7CF0B1 100%);
    padding: 10px 10px;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    z-index: 1;
    color: white;
    text-align: center;
}

.floating-card-two {
    position: relative;
    font-size: .8rem;
    position: absolute;
    right: 600px;
    top: 148px;
    width: 150px;
    background: linear-gradient(90deg, #4EA5F4 0%, #7CF0B1 100%);
    padding: 10px 10px;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    z-index: 1;
    color: white;
    text-align: center;
}

.floating-card-three {
    position: relative;
    font-size: .8rem;
    position: absolute;
    left: 250px;
    top: 148px;
    width: 150px;
    background: linear-gradient(90deg, #4EA5F4 0%, #7CF0B1 100%);
    padding: 10px 10px;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    z-index: 1;
    color: white;
    text-align: center;
}

.earnBoxHead {
    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 10px;
    padding: 5px 0;
}



@media screen and (min-width: 1900px) {
    .earn-btn {
        margin-left: 0;
    }

    .info-text {
        padding-left: 0;
        text-align: center;
    }

    .floating-card-two {
        right: 750px !important;
    }

    .floating-card-three {
        left: 380px;
    }
}

.column {
    h2 {
        color: linear-gradient(270.38deg, #7CF0B1 4.82%, #4EA5F4 95.84%);
    }
}

/* Popup MOdal*/
.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgb(0, 0, 0, 0.5);
    z-index: 999;
    padding: 0 20px;
    transition: all 1s ease;
    justify-content: center;

    .btn-close {
        font-size: 1.2rem;
        font-weight: bold;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 9991;
        color: rgb(255, 255, 255);
        background: #293280;
        border-radius: 50px;
        cursor: pointer;
        width: 26px;
        height: 26px;
        line-height: 26px;
        border: none;
        margin: 4px;
        opacity: 1;
        text-align: center;
    }
}

.box-earn {
    width: 100%;
    background-color: rgba(30, 79, 255, .5176470588235295);
    border: solid 2px #ffffff;
    color: rgb(255, 255, 255);
    padding: 10px;
    font-size: medium;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 5px;

    p {
        font-weight: 500;
    }
}

.datepicker {
    padding: 10px;
    margin-right: -50px;
    border-radius: 10px;
    width: 100%;
}

.token {
    color: #19368F;
}

input[type=text] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 2px #293280;
}

.popcontent {
    position: relative;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    background-color: white !important;
    color: black;
    max-width: 500px;
    font-size: 20px;
    height: max-content;

}

.loading {
    background: transparent !important;
}

.wallet-popbutton {
    margin: 20px 0;
}

.section-two {
    min-height: 10px !important;
}

.new {
    margin-top: -3rem !important;
}

.icon-coin {
    width: 60%;
    margin-left: 50px;
}

.curveicon {
    width: 100%;
    border-radius: 50%;
}

.cho-icon {
    width: 35%;
    margin-left: 60px;
}
.loading{
    background: transparent !important;
}