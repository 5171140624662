html {
  font-size: 62.5%;
}

.logo {
  display: grid;
  grid-column: 2/3;
  justify-content: start;
  align-items: center;
}

.logo img {
  width: 100px;
  height: auto;
}

.footer {
  background: #e8b124;
}

.Footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
}

.socials {
  width: 40%;
}

.policy {
  width: 30%;
  text-align: left;
  font-size: 16px;
}

.connect-btn-wrapper button {
  border-radius: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #e8b124 !important;
}

.info_more {
  width: 30%;
  text-align: right;
  font-size: 16px;
}

@media (max-width: 1000px) {
  .Footer {
    flex-direction: column;
    padding: 20px 0;
  }
  .socials {
    width: 100%;
  }

  .policy {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .info_more {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .main-nav {
    width: 100% !important;
  }
  .Footer {
    width: 100% !important;
  }
}

.menu-link ul {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  grid: 2/3;
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

a {
  text-decoration: none;
  color: white;
}

li {
  list-style: none;
}

.header {
  background-color: #ffffff;
}

.main-nav {
  width: 100%;
  height: 10rem;
  z-index: 100;
  display: grid;
  grid-template-columns: 10% 1fr 3fr 10%;
  cursor: pointer;
  margin: 0 auto;
  color: #1e1f1f;
}

.button {
  grid: 3/5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menuItem {
  color: #1e1f1f;
}

.ju367v93 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem !important;
  line-height: 32px;
  padding: 0.6rem 1.5rem;
  text-transform: capitalize;
  background: linear-gradient(90deg, #49a5d3 0.15%, #7cf0b2 100.15%) !important;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  display: block;
  margin: 0 auto !important;
}

.ham {
  display: none;
}

@media (max-width: 1080px) {
  .main-nav {
    height: 8rem;
    grid-template-columns: 3rem 1fr 3fr 1fr 3rem;
  }

  .menu-link ul {
    font-size: 14px;
  }

  .menu-link ul,
  .button {
    height: 8rem;
  }

  .button button {
    width: 170px;
    height: 32px;
    border-radius: 11px;
    font-size: 12px;
  }
}

.mobilelogo {
  display: none;
  margin-top: 30px;
  margin-bottom: 30px;
}

.contact-btn {
  /* color: #0D0D0D !important; */
  /* background: red ; */
}

.actived {
  border-bottom: 2px solid #e8b124;
  padding-bottom: 10px;
  color: #e8b124;
  transition: all 1s ease;
}

.navbarul {
  padding: 25px 0;
}

.navbarul li {
  padding: 10px !important;
}

@media (max-width: 900px) {
  .main-nav {
    height: 7rem;
    grid-template-columns: 3.5rem 1fr 1fr 4fr 3.5rem;
  }

  .menu-link {
    display: none;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .connectbutton {
    display: block;
  }

  .mobile-menu-link {
    display: grid;
    grid-column: 1/6;
    align-items: center;
    transform-origin: top;
    background: white;
    margin-top: -15px;
  }

  .mobilelogo {
    width: 200px;
    display: block;
  }

  .logo img {
    width: 60px;
  }

  .mobile-menu-link ul {
    display: flex;
    font-size: 24px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .mobile-menu-link ul li {
    padding-block: 10px;
  }

  .button button {
    height: 0;
    display: none;
  }

  .button {
    grid-row: 1/2;
    grid-column: 3/5;
    justify-content: end;
    align-items: center;
  }

  .actived {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  .ham {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ju367v93 {
    font-size: 1rem;
    padding: 0.2rem 1.3rem;
    margin: 1rem auto !important;
  }
}

@media (max-width: 425px) {
  .ham {
    font-size: 20px;
  }

  .mobile-menu-link ul {
    font-size: 15px;
  }

  .actived {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  .logo img {
    width: 50px;
    height: auto;
  }
}
