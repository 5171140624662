/* Navbar.css */
.navbarMain {
  background: #fff;
  color: #111827;
  padding: 2rem 0;
  position: relative;
  z-index: 999;
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 82%;
  max-width: 1200px;
  margin: 0 auto;
}

.logoNav {
  font-size: 1.5rem;
}

.logoNav img {
  height: 6rem;
}

.logoNavOption {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.menuNav {
  display: flex;
}

.menuNav a {
  color: #111827;
  margin: 0 15px;
  font-family: var(--font_outfit);
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-decoration: none;
  padding: 0rem 0rem 1rem 0rem;
  border-bottom: 2px solid transparent;
}

.menuIcon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobileMenu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 10rem;
  left: 0;
  background-color: #f5f5f5;
  width: 100%;
  padding: 2rem;
  text-align: center;
  gap: 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mobileMenu > a {
  color: #111827;
  font-family: var(--font_outfit);
  font-size: 2rem;
  font-weight: 600;
  padding: 0rem 0rem 1rem 0rem;
  border-bottom: 2px solid transparent;
}

.mainNavButtons {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.exploreNavButton {
  border-radius: 0.5rem;
  border: 1px solid #e8b124;
  background: #fff;

  display: flex;
  height: 48px;
  padding: 0.5rem 3rem;
  justify-content: center;
  align-items: center;

  color: #000;
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.5s ease;
}

.exploreNavButton:hover {
  background-color: #e8b124;
  cursor: pointer;
}

.connectNavWallet button {
  border-radius: 0.5rem !important;
  background: #e8b124 !important;

  display: flex !important;
  height: 48px !important;
  padding: 0.5rem 3rem !important;
  justify-content: center !important;
  align-items: center !important;

  color: #000 !important;

  font-family: var(--font_outfit) !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
  border: none !important;
}

.activedOp {
  border-bottom: 2px solid #e8b124 !important;
  color: #e8b124;
  transition: all 1s ease;
}

@media screen and (max-width: 1080px) {
  .navbarContainer {
    width: 95%;
  }

  .exploreNavButton {
    padding: 0.5rem 2rem;
  }

  .connectNavWallet {
    padding: 0.5rem 2rem;
  }
}

@media screen and (max-width: 900px) {
  .menuNav {
    display: none;
  }

  .menuIcon {
    display: flex;
  }

  .mobileMenu {
    display: flex;
  }

  .mobileDeviceButton {
    display: none;
  }
}

@media screen and (max-width: 620px) {
  .logoNav img {
    height: 5rem;
  }

  .deskTabDeviceButton {
    display: none;
  }

  .mobileDeviceButton {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    margin: 1rem 0;
  }

  .mobileDeviceButton > button {
    width: 100%;
    height: 48px;
  }
  .mobileDeviceButton .connectNavWallet {
    width: 100%;
    padding: 0;
  }

  .mobileDeviceButton .connectNavWallet button {
    width: 100% !important;
    height: 48px !important;
  }
}
