.mainFooter {
  width: 100%;
  overflow: hidden;
  padding: 8rem 0 3rem 0;
  border-radius: 3rem 3rem 0 0;
  position: relative;
  background-image: url(../images/shadergradient.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  /* background: linear-gradient(to bottom left, #ffeb3b, white 80%);
  background-size: 200% 200%;
  animation: windBlowing 10s infinite cubic-bezier(0.4, 0, 0.2, 1); */
}

/* @keyframes windBlowing {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
} */

.footerBackgroundGlobe {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 15rem;
}

.footerContainer {
  width: 90%;
  margin: auto;
  position: relative;
  z-index: 1;
}

.footerContent {
  /* border: 1px solid burlywood; */
  display: flex;
  justify-content: space-between;
}

.footerMainLogo {
  width: 10rem;
  height: 4.5rem;
}

.footerMainLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ------------------------------------------------------ */

.footerMainContentLinks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.accordionHeader {
  color: #000;
  font-family: var(--font_outfit);
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem; /* 133.333% */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordiionArrow {
  display: none;
}

.accordionContent {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.accordionContent > a {
  color: rgba(0, 0, 0, 0.7);
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}

.accordionContent > a:hover {
  color: #e8b124;
}

.footerNewsletter {
  width: 35%;
}

.footerNewsletter > p:nth-child(1) {
  color: #000;
  font-family: var(--font_outfit);
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem; /* 133.333% */
}

.footerNewsletter > p:nth-child(2) {
  margin-top: 2rem;
  color: rgba(0, 0, 0, 0.7);
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}

.footerNewsletter > form {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  border: 1px solid #e8b124;
  height: 4.5rem;
}

.footerNewsletter > form > input {
  border: none;
  width: 100%;
  height: 100%;
  font-family: var(--font_outfit);
  color: #000000;
  font-size: 1.7rem;
  outline: none;
  border-radius: 0;
  padding-left: 1rem;
}

.footerNewsletter > form > input::placeholder {
  font-size: 1.4rem;
}

.newsLetterSubscribe {
  height: 100%;
  display: flex;
  padding: 0 2.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e8b124;
  color: #000;
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.newsLetterSubscribe:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  scale: 1.01;
}

.footerNewsletter > p:last-child {
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.7rem; /* 142.857% */
}

/* ------------------------------------------------------ */

.footerMainSocialHandle {
  margin-top: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerMainSocialHandle > p {
  color: #000;
  font-family: var(--font_outfit);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}

.footerMainSociaLIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.footerMainSociaLIcon > a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  padding: 1rem;
  border-radius: 50%;
  font-size: 2rem;
  background: #e0e2d8;
  transition: all 0.3s ease;
}

.footerMainSociaLIcon > a:hover {
  background: #e8b124;
  color: #000;
}

/* responsive  */

@media (max-width: 1174px) {
  .footerContent {
    gap: 1rem;
  }

  .footerMainContentLinks {
    gap: 1rem;
  }
}

@media (max-width: 1024px) {
  .footerContent {
    flex-direction: column;
    gap: 3rem;
  }

  .footerMainContentLinks {
    gap: 5rem;
  }

  .footerNewsletter {
    width: 100%;
  }
}

@media (max-width: 900px) {
}

@media (max-width: 768px) {
  .footerMainContentLinks {
    gap: 4rem;
  }
}

@media (max-width: 562px) {
  .footerMainContentLinks {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .accordion {
    overflow: hidden;
    max-height: 3.5rem;
    transition: max-height 0.5s ease-in-out;
  }

  .accordionHeader {
    height: 3.5rem;
  }
  .accordiionArrow {
    display: block;
  }

  .accordion.open {
    max-height: 500px;
  }

  .accordionContent {
    margin-top: 0;
    background: #f5f5f5a9;
    padding: 2rem 0 2rem 1rem;
  }

  .footerMainSocialHandle {
    flex-direction: column;
    gap: 3rem;
  }
}

@media (max-width: 428px) {
}

@media (max-width: 390px) {
}
