.mainSwapSection {
  /* border: 1px solid red; */
  width: 100%;
  padding: 7rem 0;
}

.swapSectionContainer {
  /* border: 1px solid greenyellow; */
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  align-items: center;
}

.swapSectionImage {
  border-radius: 1.5rem;
  background: #fff7e4;
  padding: 2rem 0;
}

.swapImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swapImageTablet {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.swapImgaeMobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

/* .swapSectionContet {
  border: 1px solid red;
} */

.swapSectionContet > h1 {
  color: #000;
  font-family: var(--font_outfit);
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 2.90625rem; /* 116.25% */
}

.swapSectionContet > p {
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--font_outfit);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 137.5%; /* 1.54688rem */
  margin: 2rem 0;
}

.swapSectionContet > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.swapSectionContet > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-family: var(--font_outfit);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 137.5%; /* 1.54688rem */
}

/* responsive  */

@media (max-width: 1280px) {
  .swapSectionContainer {
    gap: 3rem;
  }
}

@media (max-width: 1024px) {
  .swapSectionContet > h1 {
    font-size: 3rem;
  }

  .swapSectionContet > p {
    font-size: 1.5rem;
  }

  .swapSectionContet > div > svg {
    width: 2.5rem;
  }

  .swapSectionContet > div > p {
    font-size: 1.5rem;
  }
}

@media (max-width: 900px) {
  .swapSectionContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
  }
  .swapImage {
    display: none;
  }

  .swapImageTablet {
    display: block;
  }

  .swapSectionContet > h1 {
    font-size: 3.5rem;
  }

  .swapSectionContet > p {
    font-size: 1.7rem;
  }

  .swapSectionContet > div > svg {
    width: 2.9rem;
  }

  .swapSectionContet > div > p {
    font-size: 1.7rem;
  }
}

@media (max-width: 768px) {
  .swapSectionContet > h1 {
    font-size: 3.2rem;
  }

  .swapSectionContet > p {
    font-size: 1.5rem;
  }

  .swapSectionContet > div > svg {
    width: 2.5rem;
  }

  .swapSectionContet > div > p {
    font-size: 1.5rem;
  }
}

@media (max-width: 562px) {
  .swapImageTablet {
    display: none;
  }

  .swapSectionContainer {
    width: 100%;
  }

  .swapSectionImage {
    border-radius: 0;
  }

  .swapImgaeMobile {
    display: block;
  }

  .swapSectionContet {
    padding: 0 3rem;
  }

  .swapSectionContet > h1 {
    font-size: 2.8rem;
  }

  .swapSectionContet > p {
    font-size: 1.3rem;
  }

  .swapSectionContet > div > svg {
    width: 2.5rem;
  }

  .swapSectionContet > div > p {
    font-size: 1.3rem;
  }
}

@media (max-width: 428px) {
  .swapSectionContet > h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 390px) {
}
