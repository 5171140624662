.settings_box {
  width: 34.3125rem;
  position: absolute;
  top: 5rem;
  right: -1.7rem;
  border-radius: 1rem;
  background: #fff;
  z-index: 10;
  padding: 2rem;
  display: none;
  gap: 2.5rem;
}

.settings_box.open {
  display: flex;
  flex-direction: column;
}

.setting_heading {
  display: flex;
  justify-content: space-between;
}

.setting_heading > p {
  color: #303030;
  font-family: var(--font_outfit);
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.setting_heading > button {
  border: none;
  font-size: 3rem;
  font-weight: 500;
  background: transparent;
  transition: all 0.5s;
  cursor: pointer;
}
.setting_heading > button:hover {
  transform: scale(1.2);
}

/* .slip_transaction_container {
  border: 1px solid red;
} */

.slip_transaction_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.slip_transaction_container > div > p {
  color: #303030;
  font-family: var(--font_outfit);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.slip_button,
.transaction_deadline {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 1rem;
}

.slip_button > button {
  border: 1px solid transparent;
  border-radius: 0.75rem;
  background: #191919;
  color: #fff;
  font-family: var(--font_outfit);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  height: 3.5rem;
  padding: 0.9375rem 1.875rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.slip_button > button:hover {
  background: transparent;
  border: 1px solid black;
  color: black;
  font-weight: bold;
}

.slip_button > input[type="number"],
.transaction_deadline > input[type="number"] {
  height: 3.5rem;
  border-radius: 0.5rem;
  border: 1px solid #c5c5c5;
  background: #fff;
  direction: ltr;
  color: #000;
  text-align: right;
}

.transaction_deadline > p {
  color: #6c6c6c;
  font-family: var(--font_outfit);
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
