.App {
  height: 100%;
  width: 100%;
}

.navbar {
  /* background: #fff; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.staking {
  /* margin-top: 6rem; */
}

.btn_primary {
  background-color: transparent;
  color: #111;
  border-radius: 12px;
  padding: 4px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 32px;
  outline: none;
  border: 2px solid #101010;
  cursor: pointer;
  width: 120px;
}

.claimtoken {
  padding: 10px 40px;
  margin: 10px 0;
  background-color: transparent;
  color: white;
  background: #775c18;
  border-radius: 12px;
  padding: 4px 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  outline: none;
  border: 2px solid #111;
  cursor: pointer;
}
.claimtoken:hover {
  background: #262627;
  transition: all 0.8ms ease-in-out;
  cursor: pointer;
}

.activebutton {
  background-color: rgba(13, 13, 13, 0.52);
  color: #fff;
}

.earnBtn:hover {
  background: linear-gradient(90.41deg, #4fa7f3 0.31%, #7cf0b2 99.66%);
}

.tokeninput {
  color: #fff;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px;
  width: 95%;
  max-width: 1100px;
  height: 100%;
  margin-bottom: 40px;
}
.infoHeading {
  font-size: 37px;
  font-weight: 700;
  padding-bottom: 1.5rem;
  color: #e8b124;
}
.infoPara {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 32px;
  max-width: 530px;
  color: black;
}

.background {
  background: #dedada4e;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 213px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #19368f;
}

.container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 0rem;
  margin: 4rem auto;
}

.card {
  background-color: #e8b124;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  border-radius: 22px;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 30px;
  min-height: 600px;
  width: 35%;
  transition: 1s;
}
.left:hover {
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.7s ease-in-out;
}
.right:hover {
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.7s ease-in-out;
}
.menuItem:hover {
  color: #e8b124;
}

.earn-left {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
  padding: 30px;
  min-height: 600px;
  width: 35%;
  position: relative;
  margin: 0 13px;
}
.earn-left:hover {
  -webkit-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.7s ease-in-out;
}
.earn-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 30px;
  min-height: 600px;
  width: 35%;
}
.earn-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 30px;
  min-height: 600px;
  width: 35%;
}

.heading h2 {
  font-size: 30px;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
}

.heading-earn h2 {
  font-size: 30px;
  font-weight: 700;
  color: #7cf0b1;
  margin-bottom: 20px;
}

.left-earn-division {
  float: left;
  width: 60%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.right-earn-division {
  float: right;
  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.left-earn-division h2 {
  font-size: 25px;
  font-weight: 200;
  color: white;
  margin-bottom: 10px;
}

.right-earn-division h2 {
  font-size: 30px;
  font-weight: 400;
  color: #7cf0b1;
  margin-bottom: 10px;
}

.earn-border {
  margin-bottom: 10px;
}

.earn-border hr {
  color: gray;
}

.heading p {
  font-size: 1.3rem;
}

.heading p span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 34px;
  /* identical to box height */

  text-transform: capitalize;

  color: #7cf0b1;
  margin-right: 1rem;
}

.bal-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}
.bal-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 200px;
  font-size: 16px;
}

.bal-left div span {
  color: #111;
}

.bal-right {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */

  text-transform: capitalize;

  color: #111;
}

#apy {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 24px;
  display: block;
  color: white;
}

.socialIcon {
  color: #fff;
  font-size: 30px;
  animation: all 0.2s ease-in-out;
}
.socialIcon:hover {
  font-size: 34px;
}

.socials {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
  background: #e8b124;
}

.tandc {
  margin-top: 5px;
}

a {
  cursor: pointer;
}

@media (max-width: 400px) {
  .socialIcon {
    color: #fff;
    font-size: 24px;
    animation: all 0.2s ease-in-out;
  }
}

.showerror {
  text-align: center;
  width: 100%;
  background: #b00020;
  margin-top: 5px;
  border-radius: 50px;
  padding: 5px;
}

.text-center {
  text-align: center;
  color: #7cf0b1;
}

.earn-btn {
  /*margin-left: 30%;*/
  margin-top: 10px;
}

.earn-btn a {
  font-size: 15px;
  margin-left: 15px;
}

.earn-border {
  border-right-style: solid;
  border-color: green;
}

.progressbar-container {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.progressbar-container:before {
  content: attr(data-label);
  font-size: 0.8em;
  vertical-align: 0;
  font-size: 15px;
  color: #fff;
  padding-left: 10px;
  /*Position text over the progress bar */
  position: absolute;
  margin-top: 5px;
}

.days {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap;
  row-gap: 10px;
}

.btn {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

/* .daysbtn {
  margin: 0 20px 0 0;
} */

.days button {
  font-size: 12px;
  /* margin-bottom: 5px;
  margin-top: 5px; */
}

.input1 {
  width: 100%;
  height: 3.5rem;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  margin-bottom: 20px;
  border: 1px solid #111111;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input1 input {
  width: 85%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  padding: 0 10px;
  border-radius: 11px 0 0 11px;
  background-color: transparent;
  /* border: 1px solid #787b83; */
}

.input1 input:focus {
  border: 1px solid #1e50ff;
}

.max {
  font-size: 12px;
  color: white;
  background: #0c0c0c;
  width: 15%;
  height: 100%;
  border-radius: 12px;
  border-radius: 0 11px 11px 0;
  padding: 0;
  border: 1px solid #e8b124;
}

.btn .btn_primary:hover,
.btn .btn_primary:active {
  background: linear-gradient(90.41deg, #775c18 0.31%, #0c0c0c 99.66%);
  color: #fff;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 20px;
  min-height: 600px;
  width: 35%;
  padding: 40px;
}
.right p {
  font-size: 21px;
  font-weight: 400;
  line-height: 32px;
  max-width: 530px;
}

.rightinner p {
  text-transform: capitalize;
  color: #111;
}
.right .big {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-bottom: 2px;
}

/* .container{
  position: relative;
  bottom: 40px;
} */

.apy {
  border: 2px solid #be8be9;
  background-color: #be8be9;
}
.apy_selected {
  border: 2px solid #be8be9;
  background-color: #be8be9;
}
.apy:hover {
  background-color: #bf8be97d;
}

/* media query at 800px */
@media screen and (max-width: 1200px) {
  .container {
    flex-direction: column;
  }
  .left {
    width: 70%;
    /* padding: 20px; */
    /* min-height: auto; */
  }
  .left-earn {
    width: 70%;
  }

  .right {
    width: 70%;
    /* padding: 20px; */
    margin-top: 50px;
    /* min-height: auto; */
  }
}
@media screen and (max-width: 450px) {
  .container {
    width: 100%;
    flex-direction: column;
    padding: 0 10px;
  }
  .left {
    width: 90%;
    padding: 20px;
    min-height: 650px;
  }
  .earn-left {
    width: 100%;
    margin: 20px 0;
  }
  .btn_primary {
    padding: 2px 8px;
  }
  .days button {
    font-size: 12px;
    /* margin-bottom: 5px;
      margin-top: 5px; */
  }

  .right {
    width: 90%;
    padding: 20px;
    margin-top: 50px;
  }

  .bal-right {
    font-size: 1.5rem;
  }

  #apy {
    font-size: 14px;
  }

  .bal-left {
    font-size: 14px;
  }

  .btn button {
    width: 45%;
    margin: 5px;
  }
  .btn button:nth-child(3) {
    width: 100%;
  }
}

@media screen and (max-width: 710px) {
  .days button {
    font-size: 10px;
  }

  .btn_primary {
    padding: 2px 10px;
  }
}

@media screen and (max-width: 560px) {
  .days button {
    font-size: 12px;
  }

  .left,
  .right {
    width: 90% !important;
  }

  .btn_primary {
    padding: 2px 8px;
  }
}

.bottom-section {
  width: 100%;
  margin-top: 10px;
}

.mobile-only {
  display: none;
}

@media only screen and (max-width: 600px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
